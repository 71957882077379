<template>
  <div class="register-box">
    <login-head title="找回密码" />
    <div class="register-content">
      <div class="register-from">
        <div class="register-title">
          <span>找回密码</span>
        </div>
        <div class="register-from-box">
          <div>
            <div class="register-success">
              <img alt="图片" src="@/assets/img/zhucechenggong.png" />
              <p>已成功向453982561@qq.com发送了重置密码邮件</p>
              <span
                >请登录邮箱点击链接重置密码。邮箱可能有延迟，请稍等1~2分钟。</span
              >
              <div>
                <span>返回</span>
                <span>进入邮箱查看</span>
              </div>
            </div>
            <div class="email-code-text">
              <p>
                <a-icon
                  type="info-circle"
                  theme="filled"
                />没有收到密码重置邮件？您可以︰
              </p>
              <span>1.到邮箱中的垃圾邮件、广告邮件目录中查找</span>
              <span>2.再次尝试找回密码</span>
            </div>
          </div>
        </div>
      </div>
      <div class="register-footer-protocol">
<!--        <div>
          <span>鸿联云帐号用户协议</span>
          <span>关于鸿联云帐号与隐私的声明</span>
          <span>常见问题</span>
        </div>-->
        <p>Copyright © 鸿联云, All Rights Reserved.</p>
      </div>
    </div>
  </div>
</template>

<script>
import loginHead from "@/page/components/loginHead.vue";
export default {
  components: {
    loginHead,
  },
  data() {
    return {};
  },
  methods: {
    // 验证码处理
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 刷新验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.obtain-code {
  color: #EA0B06;
  cursor: pointer;
}
.register-box {
  height: 100%;
  background: #f7f8fa;
  .register-content {
    height: calc(100% - 64px);
    padding: 24px 0;
    .register-from {
      background: #fff;
      width: 960px;
      margin: 0 auto;
      border-radius: 4px;
      .register-title {
        padding: 0 24px;
        height: 56px;
        border-bottom: 1px solid #f0f0f0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > span {
          font-size: 18px;
        }
      }
      .register-from-box {
        min-height: 584px;
        padding: 56px 0;
        > div {
          width: 480px;
          margin: 0 auto;
        }
      }
      .register-success {
        text-align: center;
        > img {
          display: block;
          margin: 0 auto 40px;
        }
        > p {
          line-height: 19px;
          font-size: 20px;
          margin-bottom: 16px;
        }
        > span {
          display: block;
          line-height: 13px;
          font-size: 14px;
          color: #8c8c8c;
          margin-bottom: 56px;
        }
        > div {
          display: flex;
          > span {
            flex: 1;
            text-align: center;
            height: 40px;
            background: #ffffff;
            border: 1px solid #EA0B06;
            border-radius: 2px;
            cursor: pointer;
            color: #EA0B06;
            line-height: 40px;
            font-size: 14px;
            &:first-child {
              margin-right: 16px;
            }
          }
        }
      }
      .email-code-text {
        margin-top: 56px;
        border-top: 1px solid #f0f0f0;
        padding-top: 24px;
        > p {
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 13px;
          > i {
            color: #EA0B06;
            margin-right: 8px;
          }
        }
        > span {
          line-height: 11px;
          font-size: 12px;
          color: #8c8c8c;
          margin-top: 16px;
          display: block;
          padding-left: 22px;
        }
      }
    }
    .register-footer-protocol {
      margin-top: 40px;
      > div {
        display: flex;
        justify-content: center;
        > span {
          line-height: 13px;
          font-size: 12px;
          color: #bfbfbf;
          margin-right: 12px;
          padding-right: 12px;
          border-right: 1px solid #bfbfbf;
          cursor: pointer;
          &:last-child {
            margin: 0;
            border: 0;
          }
        }
      }
      > p {
        line-height: 13px;
        font-size: 12px;
        color: #bfbfbf;
        margin-top: 16px;
        text-align: center;
      }
    }
  }
}
</style>
